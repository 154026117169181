import { DatabaseUpdateParser } from '../DatabaseUpdateParser.js';

/**
 * @typedef {object} AuthUserInfo
 * @property {string?} uid - The user's id
 * @property {string?} userGroup - The user's group
 * @property {string?} rootCompany - The user's root company
 */

/**
 * @typedef {object} JobTrackingSettings
 * @property {string} jobOwner - The owner of the job
 * @property {string} projectId - The project ID
 * @property {boolean} trackingDisabled - Whether tracking is disabled for the job
 * @property {boolean} ignoreForTracking - Whether the job should be ignored for tracking
 */

/** @type {{ admin: import('firebase-admin')?, Timestamp: typeof import('firebase-admin').firestore.Timestamp? }} */
const FIREBASE_SDK_OVERRIDE = { admin: null, Timestamp: null };

/** @type {AuthUserInfo} */
const USER_INFO_OVERRIDE = { uid: null, userGroup: null, rootCompany: null };

/**
 * Set the Firebase Admin SDK to use for the parser.
 * @param {import('firebase-admin')} admin - The Firebase Admin SDK
 * @param {typeof import('firebase-admin').firestore.Timestamp} Timestamp - The Timestamp class from the Firebase Admin SDK
 */
export function useFirebaseAdminSDK(admin, Timestamp) {
  FIREBASE_SDK_OVERRIDE.admin = admin;
  FIREBASE_SDK_OVERRIDE.Timestamp = Timestamp;
}

/**
 * Set the authenticated user info to use for the parser. Should only be called when using the admin SDK.
 * @param {AuthUserInfo} userInfo - The user's info
 */
export function overrideAuthenticatedUserInfo(userInfo) {
  USER_INFO_OVERRIDE.uid = userInfo?.uid ?? null;
  USER_INFO_OVERRIDE.userGroup = userInfo?.userGroup ?? null;
  USER_INFO_OVERRIDE.rootCompany = userInfo?.rootCompany ?? null;
}

/**
 * Get an attribute event parser.
 * @returns {DatabaseUpdateParser} attribute event parser
 */
export function getParser() {
  // Return the parser
  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'JOB_ATTRIBUTE_CHANGE',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/metadata\/(?<attributeName>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, attributeName }) => ({
          jobId,
          attributeName,
          value: _update.value,
          localTime: _timestamp,
          attributePath: _update.path
        }),
        onEventGroup: async (events) => await handleAttributeUpdates(events, 'JOB')
      },
      {
        name: 'NODE_ATTRIBUTE_CHANGE',
        pathMatch:
          /^photoheight\/jobs\/(?<jobId>[^\/]+)\/nodes\/(?<nodeId>[^\/]+)\/attributes\/(?<attributeName>[^\/]+)\/(?<instanceId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, nodeId, attributeName, instanceId }) => ({
          jobId,
          nodeId,
          attributeName,
          instanceId,
          value: _update.value,
          localTime: _timestamp,
          attributePath: _update.path
        }),
        onEventGroup: async (events) => await handleAttributeUpdates(events, 'NODE')
      },
      {
        name: 'SECTION_ATTRIBUTE_CHANGE',
        pathMatch:
          /^photoheight\/jobs\/(?<jobId>[^\/]+)\/connections\/(?<connId>[^\/]+)\/sections\/(?<sectionId>[^\/]+)\/multi_attributes\/(?<attributeName>[^\/]+)\/(?<instanceId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, connId, sectionId, attributeName, instanceId }) => ({
          jobId,
          connId,
          sectionId,
          attributeName,
          instanceId,
          value: _update.value,
          localTime: _timestamp,
          attributePath: _update.path
        }),
        onEventGroup: async (events) => await handleAttributeUpdates(events, 'SECTION')
      },
      {
        name: 'CONNECTION_ATTRIBUTE_CHANGE',
        pathMatch:
          /^photoheight\/jobs\/(?<jobId>[^\/]+)\/connections\/(?<connId>[^\/]+)\/attributes\/(?<attributeName>[^\/]+)\/(?<instanceId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, connId, attributeName, instanceId }) => ({
          jobId,
          connId,
          attributeName,
          instanceId,
          value: _update.value,
          localTime: _timestamp,
          attributePath: _update.path
        }),
        onEventGroup: async (events) => await handleAttributeUpdates(events, 'CONNECTION')
      },
      {
        name: 'JOB_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId }) => ({
          jobId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'JOB')
      },
      {
        name: 'METADATA_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/metadata$/,
        parse: async ({ _update, _timestamp, jobId }) => ({
          jobId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'JOB')
      },
      {
        name: 'NODES_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/nodes$/,
        parse: async ({ _update, _timestamp, jobId }) => ({
          jobId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'NODES')
      },
      {
        name: 'NODE_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/nodes\/(?<nodeId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, nodeId }) => ({
          jobId,
          nodeId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'NODE')
      },
      {
        name: 'NODE_ATTRIBUTE_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/nodes\/(?<nodeId>[^\/]+)\/attributes\/(?<attributeName>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, nodeId, attributeName }) => ({
          jobId,
          nodeId,
          attributeName,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'NODE_ATTRIBUTE')
      },
      {
        name: 'CONNECTIONS_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/connections$/,
        parse: async ({ _update, _timestamp, jobId }) => ({
          jobId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'CONNECTIONS')
      },
      {
        name: 'CONNECTION_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/connections\/(?<connId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, connId }) => ({
          jobId,
          connId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'CONNECTION')
      },
      {
        name: 'CONNECTION_ATTRIBUTE_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/connections\/(?<connId>[^\/]+)\/attributes\/(?<attributeName>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, connId, attributeName }) => ({
          jobId,
          connId,
          attributeName,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'CONNECTION_ATTRIBUTE')
      },
      {
        name: 'SECTIONS_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/connections\/(?<connId>[^\/]+)\/sections$/,
        parse: async ({ _update, _timestamp, jobId, connId }) => ({
          jobId,
          connId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'SECTIONS')
      },
      {
        name: 'SECTION_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/connections\/(?<connId>[^\/]+)\/sections\/(?<sectionId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId }) => ({
          jobId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'SECTION')
      },
      {
        name: 'SECTION_ATTRIBUTE_DELETED',
        pathMatch:
          /^photoheight\/jobs\/(?<jobId>[^\/]+)\/connections\/(?<connId>[^\/]+)\/sections\/(?<sectionId>[^\/]+)\/multi_attributes\/(?<attributeName>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, connId, sectionId, attributeName }) => ({
          jobId,
          connId,
          sectionId,
          attributeName,
          value: _update.value,
          localTime: _timestamp,
          attributePath: _update.path
        }),
        onEventGroup: async (events) => await handleAttributeUpdates(events, 'SECTION_ATTRIBUTE')
      }
    ]
  });
}

async function handleAttributeUpdates(events, entityType) {
  const { firestoreDB, Timestamp } = getFirebaseServices();

  // Get the timestamp of the update (from the first event)
  const timestamp = Timestamp.fromMillis(events[0].localTime.toMillis());

  // Per event group, make lookup of job tracking settings and batch writes
  const trackingSettingsLookup = {};
  const batchWrites = [];

  // Get all unique job ids from the events
  const uniqueJobIds = Array.from(new Set(events.map((event) => event.jobId)));
  const trackingSettingsByJobIdEntries = await Promise.all(
    uniqueJobIds.map(
      async (jobId) => /** @type {[string, JobTrackingSettings]} */ ([jobId, await getJobTrackingSettings(jobId, trackingSettingsLookup)])
    )
  );
  const trackingSettingsByJobId = Object.fromEntries(trackingSettingsByJobIdEntries);

  // Get the user's company and root company
  const { uid: actorUid, userGroup: companyId, rootCompany: rootCompanyId } = await getAuthenticatedUserInfo();

  await Promise.all(
    events.map(async (event) => {
      // Get the project ID for the job
      const { trackingDisabled, projectId, jobOwner, ignoreForTracking } = trackingSettingsByJobId[event.jobId];

      // If tracking is disabled for this job, skip it
      if (trackingDisabled) return;

      // The update constitutes a delete if the value is null
      const attributeWasDeleted = event.value == null;
      const entityId =
        entityType === 'JOB'
          ? event.jobId
          : entityType === 'NODE'
            ? event.nodeId
            : entityType === 'CONNECTION'
              ? event.connId
              : entityType === 'SECTION'
                ? `${event.connId}:${event.sectionId}`
                : null;

      // Compile the event document data
      const eventData = {
        company_id: companyId,
        root_company_id: rootCompanyId,
        set_by: actorUid,
        set_at: timestamp,
        job_owner: jobOwner,
        project_id: projectId,
        job_id: event.jobId,
        entity_type: entityType,
        entity_id: entityId,
        attribute: event.attributeName,
        instance_id: entityType === 'JOB' ? null : event.instanceId,
        attribute_path: event.attributePath,
        value: event.value,
        attribute_was_deleted: attributeWasDeleted,
        ignored_for_tracking: ignoreForTracking || attributeWasDeleted
      };

      const projectDoc = firestoreDB.doc(`companies/${companyId}/projects/${projectId}`);

      const latestEventDocKey = _getLatestDocKey(eventData);
      const latestEventDocRef = projectDoc.collection(`attribute_events_latest`).doc(latestEventDocKey);
      _addToChunkedBatchWrites(batchWrites, latestEventDocRef, 'SET', eventData);

      const historicalEventDocRef = projectDoc.collection(`attribute_events_history`).doc();
      _addToChunkedBatchWrites(batchWrites, historicalEventDocRef, 'SET', eventData);

      // If the value is null, we need to find all historical events and mark them as deleted
      if (attributeWasDeleted) {
        let queryType = '';
        switch (entityType) {
          case 'JOB':
            queryType = 'JOB_ATTRIBUTE';
            break;
          case 'NODE':
            queryType = 'NODE_ATTRIBUTE_INSTANCE';
            break;
          case 'CONNECTION':
            queryType = 'CONNECTION_ATTRIBUTE_INSTANCE';
            break;
          case 'SECTION':
            queryType = 'SECTION_ATTRIBUTE_INSTANCE';
            break;
        }
        // Get a query for the historical events to modify
        const queryConditions = getDeleteQueryConditions(event, queryType);
        const historicalEventsCollection = projectDoc.collection(`attribute_events_history`);

        const historicalEventsQuery = queryConditions.reduce(
          (
            /** @type {import('firebase/compat/app').default.firestore.Query|import('firebase-admin').firestore.Query} */ query,
            condition
          ) => query.where(condition.field, '==', condition.value),
          historicalEventsCollection
        );

        // Get the historical events and mark them as deleted
        const historicalEventsSnapshot = await historicalEventsQuery.get();
        historicalEventsSnapshot.docs.forEach(({ ref }) =>
          _addToChunkedBatchWrites(batchWrites, ref, 'UPDATE', { attribute_was_deleted: true, ignored_for_tracking: true })
        );
      }
    })
  );

  // Execute the batch writes
  for (const batchWriteChunk of batchWrites) {
    await batchWriteChunk.batch.commit();
  }
}

async function handleImpliedDeletes(events, scope) {
  const { Timestamp, firestoreDB } = getFirebaseServices();

  // Get the timestamp of the update (from the first event)
  const timestamp = Timestamp.fromMillis(events[0].localTime.toMillis());

  // Per event group, make lookup of job tracking settings and batch writes
  const trackingSettingsLookup = {};
  const batchWrites = [];

  // Get all unique job ids from the events
  const uniqueJobIds = Array.from(new Set(events.map((event) => event.jobId)));
  const trackingSettingsByJobIdEntries = await Promise.all(
    uniqueJobIds.map(
      async (jobId) => /** @type {[string, JobTrackingSettings]} */ ([jobId, await getJobTrackingSettings(jobId, trackingSettingsLookup)])
    )
  );
  const trackingSettingsByJobId = Object.fromEntries(trackingSettingsByJobIdEntries);

  // Get the user's company and root company
  const { uid: actorUid, userGroup: companyId, rootCompany: rootCompanyId } = await getAuthenticatedUserInfo();

  await Promise.all(
    events.map(async (event) => {
      // Get the project document
      const { trackingDisabled, projectId } = trackingSettingsByJobId[event.jobId];

      // If tracking is disabled for this job, skip it
      if (trackingDisabled) return;

      // For deletes, we need to query for the existing values and mark them for deletion
      const projectDoc = firestoreDB.doc(`companies/${companyId}/projects/${projectId}`);
      const queryConditions = getDeleteQueryConditions(event, scope);

      // Get the latest and historical events collections
      const latestEventsCollection = projectDoc.collection(`attribute_events_latest`);
      const historicalEventsCollection = projectDoc.collection(`attribute_events_history`);
      // Make two queries for the two collections that will gather the documents to modify
      let latestEventsQuery = latestEventsCollection.where('attribute_was_deleted', '==', false);
      let historicalEventsQuery = historicalEventsCollection.where('attribute_was_deleted', '==', false);
      for (const condition of queryConditions) {
        latestEventsQuery = latestEventsQuery.where(condition.field, '==', condition.value);
        historicalEventsQuery = historicalEventsQuery.where(condition.field, '==', condition.value);
      }

      // Get the latest and historical events to modify
      const [latestEventsSnapshot, historyEventsSnapshot] = await Promise.all([latestEventsQuery.get(), historicalEventsQuery.get()]);

      const latestEventUpdates = {
        company_id: companyId,
        root_company_id: rootCompanyId,
        set_by: actorUid,
        set_at: timestamp,
        value: null,
        attribute_was_deleted: true,
        ignored_for_tracking: true
      };

      latestEventsSnapshot.docs.forEach((doc) => {
        // Update "latest" doc so it reflects deletion by the actor
        _addToChunkedBatchWrites(batchWrites, doc.ref, 'UPDATE', latestEventUpdates);
        // Add a "null" event to the history collection
        const latestEventData = doc.data();
        _addToChunkedBatchWrites(batchWrites, historicalEventsCollection.doc(), 'SET', { ...latestEventData, ...latestEventUpdates });
      });
      // Mark "history" docs as deleted
      historyEventsSnapshot.docs.forEach(({ ref }) =>
        _addToChunkedBatchWrites(batchWrites, ref, 'UPDATE', { attribute_was_deleted: true, ignored_for_tracking: true })
      );
    })
  );

  // Execute the batch writes
  for (const batchWriteChunk of batchWrites) {
    await batchWriteChunk.batch.commit();
  }
}

/**
 * Get the tracking settings for a job.
 * @param {string} jobId - ID of the job
 * @param {Object} trackingSettingsLookup - Lookup of job tracking settings (by job ID)
 * @returns {Promise<JobTrackingSettings>} - The tracking
 * settings for the job
 */
async function getJobTrackingSettings(jobId, trackingSettingsLookup) {
  const { realtimeDB } = getFirebaseServices();

  // Try to get the job settings from the local lookup
  const trackingSettingsFromLocalLookup = trackingSettingsLookup[jobId];
  if (trackingSettingsFromLocalLookup) return trackingSettingsFromLocalLookup;

  // If the job wasn't in the lookup, try to fetch the settings from the database
  const jobRef = realtimeDB.ref(`photoheight/jobs/${jobId}`);

  // Get the owner of the job and the project it belongs to
  const jobOwner = (await jobRef.child(`job_owner`).once('value')).val();
  // FEAT (06-15-2022): "job creator" is temporary, eventually we'll want a project key here
  const projectId = (await jobRef.child(`job_creator`).once('value')).val();

  // See if tracking is disabled for this job
  const trackingDisabled = Boolean((await jobRef.child(`tracking_disabled`).once('value')).val());

  // See if the job should have tracking ignored
  const isDuplicatedJob = (await jobRef.child(`metadata/duplicated_job`).once('value')).val();
  const isJobSnapshot = (await jobRef.child(`metadata/snapshot_of_job`).once('value')).val();
  const ignoreForTracking = Boolean(isDuplicatedJob || isJobSnapshot);

  // Compile the tracking settings and add to local lookup
  const trackingSettings = { jobOwner, projectId, trackingDisabled, ignoreForTracking };
  trackingSettingsLookup[jobId] = trackingSettings;

  return trackingSettings;
}

/**
 * Add an operation to a chunked batch write.
 * @param {array} batchWriteChunks - Array of batch write objects
 * @param {object} docRef - Reference to the document to add to the batch
 * @param {string} operation - Operation to perform on the document (SET, UPDATE, DELETE)
 * @param {object} eventData - Data for the operation
 */
function _addToChunkedBatchWrites(batchWriteChunks, docRef, operation, eventData) {
  const { firestoreDB } = getFirebaseServices();
  const MAX_BATCH_SIZE = 250;

  const lastBatchWriteChunk = batchWriteChunks.at(-1);
  if (lastBatchWriteChunk == null || lastBatchWriteChunk?.size >= MAX_BATCH_SIZE) {
    const batch = firestoreDB.batch();
    batchWriteChunks.push({ batch, size: 0 });
  }
  const batchWriteChunk = batchWriteChunks.at(-1);

  switch (operation) {
    case 'SET':
      batchWriteChunk.batch.set(docRef, eventData);
      break;
    case 'UPDATE':
      batchWriteChunk.batch.update(docRef, eventData);
      break;
    case 'DELETE':
      batchWriteChunk.batch.delete(docRef);
      break;
  }
  batchWriteChunk.size++;
}

/** Returns a one-way hash for an event doc (used for the "latest" bucket) */
function _getLatestDocKey(eventData) {
  const entityStr = eventData.job_id + eventData.entity_id + eventData.entity_type;
  const attributeStr = eventData.attribute + eventData.instance_id;
  const entityHash = _cyrb53(entityStr);
  const attributeHash = _cyrb53(attributeStr);

  // We reverse these so they are a bit more lexicographically distributed
  return attributeHash + entityHash;
}

/**
 * A simple, high quality 53-bit hash (source: https://stackoverflow.com/a/52171480/16625307)
 * @param {string} str - string to hash
 * @param {number} seed - seed number for alternate hashes with the same input
 * @returns integer that is the 53-bit hash of the input string
 */
function _cyrb53(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return (h2 >>> 0).toString(16).padStart(8, '0') + (h1 >>> 0).toString(16).padStart(8, '0');
}

/**
 * Get the conditions for a query to find all documents to be modified in a delete.
 * @param {object} event - an event object
 * @param {string} scope - the scope of the event (one of JOB, JOB_ATTRIBUTE, NODES, NODE, NODE_ATTRIBUTE, NODE_ATTRIBUTE_INSTANCE)
 * @returns {object[]} an array of query conditions for the event
 */
function getDeleteQueryConditions(event, scope) {
  // We always want to limit to the job ID
  const conditions = [{ field: 'job_id', value: event.jobId }];

  // For any other scope besides "JOB", we need to add additional conditions
  if (scope === 'JOB_ATTRIBUTE') {
    conditions.push({ field: 'entity_type', value: 'JOB' }, { field: 'attribute', value: event.attributeName });
  } else if (scope === 'NODES') {
    conditions.push({ field: 'entity_type', value: 'NODE' });
  } else if (scope === 'NODE') {
    conditions.push({ field: 'entity_type', value: 'NODE' }, { field: 'entity_id', value: event.nodeId });
  } else if (scope === 'NODE_ATTRIBUTE') {
    conditions.push(
      { field: 'entity_type', value: 'NODE' },
      { field: 'entity_id', value: event.nodeId },
      { field: 'attribute', value: event.attributeName }
    );
  } else if (scope === 'NODE_ATTRIBUTE_INSTANCE') {
    conditions.push(
      { field: 'entity_type', value: 'NODE' },
      { field: 'entity_id', value: event.nodeId },
      { field: 'attribute', value: event.attributeName },
      { field: 'instance_id', value: event.instanceId }
    );
  } else if (scope === 'CONNECTIONS') {
    conditions.push({ field: 'entity_type', value: 'CONNECTION' });
  } else if (scope === 'CONNECTION') {
    conditions.push({ field: 'entity_type', value: 'CONNECTION' }, { field: 'entity_id', value: event.connId });
  } else if (scope === 'CONNECTION_ATTRIBUTE') {
    conditions.push(
      { field: 'entity_type', value: 'CONNECTION' },
      { field: 'entity_id', value: event.connId },
      { field: 'attribute', value: event.attributeName }
    );
  } else if (scope === 'CONNECTION_ATTRIBUTE_INSTANCE') {
    conditions.push(
      { field: 'entity_type', value: 'CONNECTION' },
      { field: 'entity_id', value: event.connId },
      { field: 'attribute', value: event.attributeName },
      { field: 'instance_id', value: event.instanceId }
    );
  } else if (scope === 'SECTIONS') {
    conditions.push({ field: 'entity_type', value: 'SECTION' });
  } else if (scope === 'SECTION') {
    conditions.push({ field: 'entity_type', value: 'SECTION' }, { field: 'entity_id', value: event.sectionId });
  } else if (scope === 'SECTION_ATTRIBUTE') {
    conditions.push(
      { field: 'entity_type', value: 'SECTION' },
      { field: 'entity_id', value: event.sectionId },
      { field: 'attribute', value: event.attributeName }
    );
  } else if (scope === 'SECTION_ATTRIBUTE_INSTANCE') {
    conditions.push(
      { field: 'entity_type', value: 'SECTION' },
      { field: 'entity_id', value: event.sectionId },
      { field: 'attribute', value: event.attributeName },
      { field: 'instance_id', value: event.instanceId }
    );
  }

  return conditions;
}

/**
 * Get the Firebase SDK to use for the parser. Uses the Admin SDK override if available, then falls back to the globalThis.firebase, except
 * for the database, which uses the globalThis.FirebaseWorker if it can.
 */
function getFirebaseServices() {
  const firebase =
    FIREBASE_SDK_OVERRIDE.admin ?? /** @type {import('firebase/compat/app').default} */ (/** @type {unknown} */ (globalThis.firebase));
  return {
    realtimeDB:
      typeof globalThis.FirebaseWorker != 'undefined'
        ? /** @type {import('firebase/compat/app').default.database.Database} */
          (globalThis.FirebaseWorker.database())
        : firebase.database(),
    firestoreDB: firebase.firestore(),
    auth: firebase.auth(),
    Timestamp: FIREBASE_SDK_OVERRIDE.Timestamp ?? firebase.firestore.Timestamp
  };
}

/**
 * Get info about the authenticated user.
 * @returns {Promise<AuthUserInfo>} - The user's group, root company, and UID (null if not
 * authenticated)
 */
async function getAuthenticatedUserInfo() {
  // If we have an override, use it
  if (Object.values(USER_INFO_OVERRIDE).some((value) => value != null)) {
    return USER_INFO_OVERRIDE;
  }

  // Otherwise, get the info from the Firebase SDK
  const { auth } = getFirebaseServices();
  const currentUser = 'currentUser' in auth && auth.currentUser;
  if (!currentUser) return { userGroup: null, rootCompany: null, uid: null };

  const tokenResult = await currentUser.getIdTokenResult();
  const { userGroup = null, rootCompany = null } = tokenResult.claims;
  return { uid: currentUser.uid, userGroup, rootCompany };
}
