import { DatabaseUpdateParser, getAuthenticatedUserInfo, getFirebaseServices } from '../DatabaseUpdateParser.js';

/** @import { UpdateParserOverrides } from '../DatabaseUpdateParser.js' */

/**
 * Get a parser for node last updated events on jobs
 * @param {UpdateParserOverrides?} [OVERRIDES] - Overrides for using this parser in different contexts (e.g. on the server with admin)
 * @returns {DatabaseUpdateParser} A node-last-updated parser
 */
export function getNodeLastUpdatedParser(OVERRIDES = null) {
  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'NODE_CHANGED',
        pathMatch: /^photoheight\/jobs\/(?<jobId>[^\/\s]+)\/nodes\/(?<nodeId>[^\/\s]+)(?:\/.*)?$/,
        parse: async ({ _update, _timestamp, jobId, nodeId }) => ({
          jobId,
          nodeId,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/nodes/${nodeId}`
        }),
        onEventGroup: async (events) => await writeNodeEventsToFirestore(events, OVERRIDES)
      }
    ]
  });
}

/**
 * Write node last updated events to Firestore
 * @param {Array<Object>} events - array of event objects
 * @param {UpdateParserOverrides?} OVERRIDES - Overrides for using this parser in different contexts (e.g. on the server with admin)
 */
async function writeNodeEventsToFirestore(events, OVERRIDES) {
  const { realtimeDB, firestoreDB, FieldValue } = getFirebaseServices(OVERRIDES);
  // Get a list of all the unique job-nodes that were updated
  const jobSubPaths = [...new Set(events.map((event) => event.jobSubPath))];
  // Get the logged-in user's id and active company
  const { uid: userId, userGroup } = await getAuthenticatedUserInfo(OVERRIDES);

  // Get a ref to the jobs list
  const jobsRef = realtimeDB.ref(`photoheight/jobs`);
  const jobOwnersByJobId = {};

  // For each job node, create geo-location event records for each node
  await Promise.all(
    jobSubPaths.map(async (jobSubPath) => {
      const nodeUpdate = events.find((event) => event.jobSubPath == jobSubPath);
      if (!nodeUpdate) throw new Error(`No node update found for job sub path: ${jobSubPath}`);
      const { nodeId, jobId } = nodeUpdate;

      // Get the job owner from the cache (or fetch it and cache it)
      const jobOwner = (jobOwnersByJobId[jobId] ??= (await jobsRef.child(`${jobId}/job_owner`).once('value')).val());

      // Create the node-event record
      const nodeEvent = {
        node_id: nodeId,
        node_path: `photoheight/jobs/${jobSubPath}`,
        job_id: jobId,
        owner_company_id: jobOwner,
        last_updated: FieldValue.serverTimestamp(),
        last_updated_by: { uid: userId, user_group: userGroup }
      };

      // Add the node-event to the firestore collection
      await firestoreDB.doc(`jobs/${jobId}/nodes_last_updated/${nodeId}`).set(nodeEvent);
    })
  );
}
