// only allow certain browsers
import './browserCheck.js';
import { PolymerElement } from '@polymer/polymer';

// keep track if we are using mobile
self.isMobile = location.pathname.includes('mobile');
// keep track if the origin is a development environment
import {
  checkIsDevelopmentOrigin,
  checkIsInternalOrigin,
  checkIsLegacyOrigin,
  checkIsChromeExtensionOrigin
} from '../modules/CheckOrigin.js';

// Load firebase and make global.
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
self.firebase = firebase;

import './config.js';
import { ParseCSSColor } from '../modules/_deprecated/ParseCSSColor.js';
import '../js/firebaseWorker/firebaseWorker.js';
import AppName from './appName.js';
import { initSentry } from './sentry.js';
import { initPostHog } from './posthog.js';
import 'polymerfire/polymerfire.js';
import '../elements/katapult-firebase-worker/katapult-firebase-worker.js';
import { init as InitFeatureFlags } from '../modules/FeatureFlags.js';
import { getGeoEventsParser } from '../modules/update-parsers/GeoEventsParser.js';
import { getLastUpdatedParser } from '../modules/update-parsers/LastUpdatedParser.js';
import { getAttributeEventsParser } from '../modules/update-parsers/AttributeEventsParser.js';
import { getNodeLastUpdatedParser } from '../modules/update-parsers/NodeLastUpdatedParser.js';
import { getEntityDeletedParser } from '../modules/update-parsers/EntityDeletedParser.js';
import { KLogic } from '../modules/KLogic.js';

/** Initialize firebase */
let appName = '';
let shouldUpdateSessionAppName = false;

// Check if the origin is a development or internal environment
const isDevelopmentOrigin = checkIsDevelopmentOrigin();
const isChromeExtensionOrigin = checkIsChromeExtensionOrigin();
const isInternalOrigin = checkIsInternalOrigin();
const isLegacyOrigin = checkIsLegacyOrigin();

// If you are a developer or you are on beta or this is a chrome extension
if (isDevelopmentOrigin || isInternalOrigin || isChromeExtensionOrigin) {
  if (isDevelopmentOrigin && typeof globalThis.lockedAppName !== 'undefined' && globalThis.lockedAppName) {
    shouldUpdateSessionAppName = true;
    appName = globalThis.lockedAppName;
  } else {
    // If the app name doesn't exist in the session storage, then we should update it.
    shouldUpdateSessionAppName = !AppName.getSessionStorage();
    // Get the app name (either from session or local storage).
    appName = AppName.get();
  }
}

// Initialize Sentry on production (except for dev and legacy domains to avoid false regressions)
if (!isDevelopmentOrigin && !isLegacyOrigin) initSentry(appName);

// Initialize PostHog on production (except for development origins)
if (!isDevelopmentOrigin) initPostHog();

// set the config appName if necessary
if (appName) self.config.appName = appName;
if (firebase.apps.length) throw new Error('Failed to initialize firebase app: app already exists!');
let firebaseConfig = self.config.firebaseConfigs[self.config.appName];
if (!firebaseConfig)
  throw new Error('Failed to initialize firebase app: no firebase config found for app name ' + (self.config.appName || null) + '!');
else {
  firebase.initializeApp(firebaseConfig);

  // firebase.functions().useEmulator('localhost', 8080);
  // firebase.auth().useEmulator('http://localhost:9099');
  // firebase.firestore().useEmulator('localhost', 8083);

  // if we got the appName from indexedDB and we are using a database switching capable domain then set the appName in session storage
  // also if this is a locked page then update the appName in session storage to prevent false data
  if (shouldUpdateSessionAppName) AppName.setSessionStorage(self.config.appName);
  // Initialize FirebaseWorker
  FirebaseWorker._init();
  self.dispatchEvent(new CustomEvent('firebase-app-initialized'));

  // Set up feature flagging
  InitFeatureFlags(FirebaseWorker.database());

  monitorTokenRefresh();

  // Enable offline persistence for Firestore if we are on a mobile device
  if (self.isMobile) {
    firebase
      .firestore()
      .enablePersistence({ synchronizeTabs: true })
      .then(() => {
        console.info('Enabled offline persistence for Firestore');
      })
      .catch((err) => {
        console.warn('Failed to enable offline persistence:', err);
      });
  }
}

/**
 * Sets up a monitor to watch if the user's token has a refresh time that
 * differs from what we store locally and updates the token if so.
 */
function monitorTokenRefresh() {
  // We want to refresh the token if the updated time does not match our locally tracked time
  let disconnectUserTokenListener = null;
  const localTokenRefreshTimeStoragePath = `${globalThis.config.appName}_token_refresh_time`;
  globalThis.firebase.auth().onAuthStateChanged((user) => {
    // Remove previous listener if it exists
    disconnectUserTokenListener?.();
    // On user login add new listener
    if (user) {
      // Get a reference to the user's token refresh time
      const userTokenRefreshRef = globalThis.firebase.database().ref(`token_status/${user.uid}/refresh_time`);
      // Setup a callback to force refresh to pick up the latest custom claims changes
      const tokenRefreshCallback = (s) => {
        // If the user has a null refresh_time from the database and we store that in local
        // storage, it will be stored as the string "null". But if we get a value back from
        // local storage that is actually null, that means the value was never set. So if
        // the local value actually is null, we should update the token. Otherwise, we should
        // update the token only when the local and database values don't match (handling the case
        // of null from the database and the string "null" from local storage)

        // Fall back to the string "null" if the value is null to help the local storage comparison
        const refreshTime = s.val() || 'null';
        const localTokenRefreshTime = localStorage?.getItem(localTokenRefreshTimeStoragePath);
        // Check if the local storage value is actually null, meaning it was never set
        const localTimeIsNull = localTokenRefreshTime === null;
        if (localTimeIsNull || localTokenRefreshTime != refreshTime) {
          user.getIdToken(true);
          localStorage?.setItem(localTokenRefreshTimeStoragePath, refreshTime);
        }
      };
      // Setup listener to disconnect the callback.
      disconnectUserTokenListener = () => {
        userTokenRefreshRef.off('value', tokenRefreshCallback);
        disconnectUserTokenListener = null;
      };
      // Subscribe new listener
      userTokenRefreshRef.on('value', tokenRefreshCallback);
    }
  });
}

class AppConfiguration {
  static get config() {
    return self.config;
  }
  constructor(polymerElement, options) {
    options = options || {};
    // Store a reference to the polymer element, the name of the property of the element which is to store the configuration data, and a local copy of configuration data.
    this.polymerElement = polymerElement || null;
    this.elementName = this.polymerElement ? `<${this.polymerElement.localName}>` : '(No Element Given)';
    this.configKey = options.propertyName || 'config';

    let localStorageFirebaseData = JSON.parse(self.localStorage.getItem(`${self.config.appName}_white_label`) || null);
    if (localStorageFirebaseData && !localStorageFirebaseData.error) {
      self.config.firebaseData = localStorageFirebaseData;
      // Write local data (in config.js) and default palette (or stored palette) to element.
      this.writeConfigToElement();
      this.applyPalette();
    }

    // Fetch app data stored in firebase.
    FirebaseWorker.ref('photoheight/white_label')
      .once('value')
      .then((snapshot) => {
        // Once loaded, update config, write the new data to the element, and update the styling of the element using any styling found in firebase.
        self.config.firebaseData = snapshot.val();
        this.writeConfigToElement();
        this.applyPalette();
        // Store firebase data in local storage for immediate use next time.
        self.localStorage.setItem(`${self.config.appName}_white_label`, JSON.stringify(self.config.firebaseData));
        // Run firebase data loaded callback.
        if (options.dataLoaded && typeof options.dataLoaded === 'function') options.dataLoaded(self.config);
      })
      .catch((err) => {
        console.warn('appConfiguration firebase data failed to load in ' + this.elementName + '\n', err);
      });

    // Attach update parsers (will auto-detach existing parsers if this constructor is somehow called more than once)
    globalThis.FirebaseWorker.attachUpdateParser('attribute-event-parser', getAttributeEventsParser());
    globalThis.FirebaseWorker.attachUpdateParser('last-updated-parser', getLastUpdatedParser());
    globalThis.FirebaseWorker.attachUpdateParser('geo-events-parser', getGeoEventsParser());
    globalThis.FirebaseWorker.attachUpdateParser('node-last-updated-parser', getNodeLastUpdatedParser());
    globalThis.FirebaseWorker.attachUpdateParser('entity-deleted-parser', getEntityDeletedParser());

    // Listen for user permissions to be set
    if (self.katapultAuth) {
      katapultAuth.addEventListener('user-permissions-set', () => {
        // Setup action tracking.
        if (this.polymerElement.isKatapultPageElement) {
          // For each variant of job-id, create a property observer for action tracking
          ['jobId', 'jobid', 'job_id'].forEach((prop) => {
            this.polymerElement._createPropertyObserver(prop, (val) => this.registerActionTracking(val));
            let initVal = this.polymerElement.get(prop);
            if (initVal) this.registerActionTracking(initVal);
          });
        }
      });
    }
  }

  applyPalette() {
    // Loop through all keys in config.firebaseData.palette, convert them to css variable names, and apply the values to them.
    if (self.config.firebaseData.palette) {
      let palette = self.config.firebaseData.palette;
      let lightColor = '#FFFFFF';
      let darkColor = '#212121';
      // Ensure default text colors exist.
      if (!palette.primaryTextColor) palette.primaryTextColor = darkColor;
      let rgb = ParseCSSColor(darkColor);
      let faded = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${rgb[3] - 0.2})`;
      if (!palette.primaryTextColorFaded) palette.primaryTextColorFaded = faded;
      // Calc text for color versions.
      ['primaryColor', 'secondaryColor'].forEach((x) => {
        let color = this.colorBrightness(ParseCSSColor(palette[x])) > 0.6 ? darkColor : lightColor;
        let rgb = ParseCSSColor(color);
        let faded = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${rgb[3] - 0.2})`;
        if (!palette[x + 'TextColor']) palette[x + 'TextColor'] = color;
        if (!palette[x + 'TextColorFaded']) palette[x + 'TextColorFaded'] = faded;
      });

      // Setup shoelace colors.
      palette.slColorPrimary = palette.secondaryColor;
      palette.slColorPrimaryText = palette.secondaryColorTextColor;
      palette.slColorSecondary = palette.primaryColor;
      palette.slColorSecondaryText = palette.primaryColorTextColor;

      for (let key in palette) {
        let propertyName =
          '--' +
          key
            .replace(/\W+/g, '-')
            .replace(/([a-z\d])([A-Z])/g, '$1-$2')
            .toLowerCase();
        let val = palette[key];
        document.documentElement.style.setProperty(propertyName, val);
      }
      window.dispatchEvent(new CustomEvent('palette-applied'));
    }
  }

  colorBrightness(rgb) {
    // Takes in a color and determines how bright it is on a scale from 0-255
    return Math.sqrt(rgb[0] * rgb[0] * 0.241 + rgb[1] * rgb[1] * 0.691 + rgb[2] * rgb[2] * 0.068) / 255;
  }

  writeConfigToElement() {
    if (this.polymerElement && this.polymerElement instanceof PolymerElement) {
      // Set the configKey property on the element to be the configuration data.
      this.polymerElement.set(this.configKey, self.config);
      // Notify all paths on the element for config.firebaseData.*.
      for (let key in self.config.firebaseData) {
        this.polymerElement.notifyPath(this.configKey + '.firebaseData.' + key);
      }
    } else this.polymerElement[this.configKey] = self.config;
  }

  async registerActionTracking(jobId) {
    // Clear the existing action tracking models.
    FirebaseWorker.setActionTrackingModels(null);

    if (jobId == null) {
      console.debug('Action tracking and attribute tracking disabled in', this.elementName);
      return;
    }

    // Return if jobId is an empty string
    if (!jobId) return;

    if (!katapultAuth.userGroup) return;

    const userGroup = katapultAuth.userGroup;
    if (this.companyActionTrackingModels?.userGroup != userGroup) {
      this.companyActionTrackingModels = await FirebaseWorker.ref(`photoheight/company_space/${userGroup}/project_tracking/action_tracking`)
        .once('value')
        .then((s) => s.val());
      if (this.companyActionTrackingModels) this.companyActionTrackingModels.userGroup = userGroup;
    }

    if (!this.companyActionTrackingModels) return;

    const jobRef = FirebaseWorker.ref(`photoheight/jobs/${jobId}`);

    // Get the job's tracking model
    const metadataRef = jobRef.child(`metadata`);
    // Detach tracking model listener
    if (this.metadataListener) metadataRef.off('value', this.metadataListener);
    // Try to get action tracking models for this job.
    this.metadataListener = metadataRef.on('value', async (s) => {
      const metadata = s.val() ?? {};
      const trackingModels = [];
      for (const key in this.companyActionTrackingModels) {
        const model = this.companyActionTrackingModels[key];
        if (KLogic.compute(model.job_filter, { job_id: jobId, metadata })) {
          trackingModels.push(model.actions);
        }
      }
      if (trackingModels.length) {
        FirebaseWorker.setActionTrackingModels(Object.assign({}, ...trackingModels), katapultAuth.userGroup);
        console.debug('Action tracking configured in', this.elementName);
      }
      // Otherwise, unset the tracking model
      else {
        FirebaseWorker.setActionTrackingModels({}, katapultAuth.userGroup);
        console.debug('Action tracking disabled in', this.elementName);
      }
    });
  }
}
self.AppConfiguration = AppConfiguration;
