import { init, browserTracingIntegration } from '@sentry/browser';
import { config } from './config';
import { checkIsDevelopmentOrigin, checkIsPreviewChannelOrigin, getVersionNameFromOrigin } from '../modules/CheckOrigin.js';

/**
 * Initialize Sentry for error reporting and performance monitoring
 * @param {string} appName - The server name to use for Sentry
 */
export function initSentry(appName) {
  // Initialize Sentry
  init({
    dsn: 'https://91aded4dd2f345d590345a68f83f9872@o4504565526364160.ingest.sentry.io/4504565552447488',
    integrations: [browserTracingIntegration()],

    // Identify the version of the software
    environment: getSentryEnvironment(),
    release: `katapult-pro@${config.version}`,

    initialScope: {
      // Use the passed-in app name (for beta) or default to the config version (for everyone else)
      tags: { server_name: appName || config.appName }
    },

    // Capture 50% of errors for error reporting
    sampleRate: 0.5,

    // Capture 10% of transactions for performance monitoring
    tracesSampleRate: 0.1,

    // Ignore errors whose message matches these strings
    // See https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    // for full list of common ignore rules
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS'
    ],
    // Ignore errors from these URLs
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i
    ]
  });

  // Add a global flag
  Object.assign(globalThis, { SENTRY_IS_INITIALIZED: true });
}

/**
 * Returns the name of the environment based on the origin and hostname of the current location.
 *
 * Note: This is a somewhat hacky way of determining the environment which really ought to be set by `.circleci/config.yml` and
 * `cloudbuild.yaml`.
 * @returns {string} The name of the environment
 */
export function getSentryEnvironment() {
  if (checkIsDevelopmentOrigin()) return 'development';
  if (checkIsPreviewChannelOrigin()) return 'preview-channel';

  const versionName = getVersionNameFromOrigin();
  if (versionName == 'alpha') return 'early-access';
  if (versionName == 'beta') return 'release-candidate';
  if (versionName == 'legacy') return 'legacy';
  return 'current-release';
}
