import posthog from 'posthog-js';
import { config } from './config.js';

/**
 * Initializes the PostHog analytics library for tracking user interactions.
 */
export function initPostHog() {
  // Initialize PostHog
  posthog.init(config.postHogApiKey, {
    api_host: 'https://us.i.posthog.com',
    autocapture: false,
    person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
  });
}
