import { DatabaseUpdateParser, getAuthenticatedUserInfo, getFirebaseServices } from '../DatabaseUpdateParser.js';

/** @import { UpdateParserOverrides } from '../DatabaseUpdateParser.js' */

/**
 * Get a parser for entity deleted events on jobs
 * @param {UpdateParserOverrides?} [OVERRIDES] - Overrides for using this parser in different contexts (e.g. on the server with admin)
 * @returns {DatabaseUpdateParser} An entity-deleted parser
 */
export function getEntityDeletedParser(OVERRIDES = null) {
  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'NODE_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/nodes\/(?<nodeId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, nodeId }) => ({
          jobId,
          nodeId,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/nodes/${nodeId}`
        }),
        onEventGroup: async (events) => await handleSingleNodeDeleted(events, OVERRIDES)
      },
      {
        name: 'ALL_NODES_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)($|\/nodes$)/,
        parse: async ({ _timestamp, jobId }) => ({ jobId, localTime: _timestamp }),
        onEventGroup: async (events) => await handleAllNodesDeleted(events, OVERRIDES)
      }
    ]
  });
}

/**
 * Write node last updated events to Firestore
 * @param {Array<Object>} events - array of event objects
 * @param {UpdateParserOverrides?} OVERRIDES - Overrides for using this parser in different contexts (e.g. on the server with admin)
 */
async function handleSingleNodeDeleted(events, OVERRIDES) {
  const { realtimeDB, firestoreDB, FieldValue } = getFirebaseServices(OVERRIDES);
  // Get a list of all the unique job-nodes that were updated
  const jobSubPaths = [...new Set(events.map((event) => event.jobSubPath))];
  // Get the logged-in user's id and active company
  const { uid: userId, userGroup } = await getAuthenticatedUserInfo(OVERRIDES);
  // Get a ref to the jobs list
  const jobsRef = realtimeDB.ref(`photoheight/jobs`);
  const jobOwnersByJobId = {};

  // For each job node, create geo-location event records for each node
  await Promise.all(
    jobSubPaths.map(async (jobSubPath) => {
      const nodeUpdate = events.find((event) => event.jobSubPath == jobSubPath);
      const { jobId, nodeId } = nodeUpdate;

      // Get the job owner
      const jobOwner = (jobOwnersByJobId[jobId] ??= (await jobsRef.child(`${jobId}/job_owner`).once('value')).val());

      // Create the node-event record
      const nodeEvent = {
        node_path: `photoheight/jobs/${jobSubPath}`,
        entityId: nodeId,
        entityType: 'NODE',
        job_id: jobId,
        owner_company_id: jobOwner,
        deleted_at: FieldValue.serverTimestamp(),
        deleted_by: { uid: userId, user_group: userGroup }
      };

      // Add the node-event to the firestore collection
      await firestoreDB.doc(`jobs/${jobId}/entity_deleted_events/${nodeId}`).set(nodeEvent);
    })
  );
}

/**
 * Handle implied deletes for nodes
 * @param {Array<Object>} events - array of event objects
 * @param {UpdateParserOverrides?} OVERRIDES - Overrides for using this parser in different contexts (e.g. on the server with admin)
 */
async function handleAllNodesDeleted(events, OVERRIDES) {
  const { Timestamp, firestoreDB } = getFirebaseServices(OVERRIDES);
  // Get the timestamp of the update (from the first event)
  const timestamp = Timestamp.fromMillis(events[0].localTime.toMillis());
  // Get the user's authentication information
  const { uid: userId, userGroup } = await getAuthenticatedUserInfo(OVERRIDES);

  await Promise.all(
    events.map(async (event) => {
      // Get the job document and the collections of nodes that have been updated and deleted
      const jobDocument = firestoreDB.doc(`jobs/${event.jobId}`);
      const jobNodesLastUpdatedCollection = jobDocument.collection(`nodes_last_updated`);
      const jobNodesAlreadyDeletedQuery = jobDocument.collection(`entity_deleted_events`).where('entityType', '==', 'NODE');

      // Get the data from all of the documents
      const [nodesLastUpdated, nodesAlreadyDeleted] = await Promise.all([
        (await jobNodesLastUpdatedCollection.get()).docs.map((doc) => doc.data()),
        (await jobNodesAlreadyDeletedQuery.get()).docs.map((doc) => doc.data())
      ]);

      // Filter out nodes that have already been deleted
      const nodesToReportAsDeleted = nodesLastUpdated.filter(
        (node) => !nodesAlreadyDeleted.some((deletedNode) => deletedNode.entityId === node.node_id)
      );

      nodesToReportAsDeleted.forEach(async (nodeData) => {
        // Create the node-event record
        const nodeEvent = {
          node_path: nodeData.node_path,
          entityId: nodeData.node_id,
          entityType: 'NODE',
          job_id: event.jobId,
          owner_company_id: nodeData?.owner_company_id,
          deleted_at: timestamp,
          deleted_by: { uid: userId, user_group: userGroup }
        };

        // Update "latest" doc so it reflects deletion by the actor
        await firestoreDB.doc(`jobs/${event.jobId}/entity_deleted_events/${nodeData.node_id}`).set(nodeEvent);
      });
    })
  );
}
