import { DateTime } from 'luxon';
import { DatabaseUpdateParser } from '../DatabaseUpdateParser.js';

/**
 * Get a parser that updates the last updated timestamp of a job
 * @param {object} config - Config object to set up the parser
 * @param {string} config.uid - The actor's user id
 * @param {object} [config.firebase] - Firebase API to use (defaults to globalThis.firebase)
 * @param {object} [config.adminFieldValue] - The Admin SDK `FieldValue` to use for the update
 * @returns {DatabaseUpdateParser} A job-last-updated parser
 */
export function getParser(config) {
  // Get a reference to the firebase instance.
  const firebase = config.firebase ?? globalThis.firebase;
  const FieldValue = config.adminFieldValue ?? firebase.firestore.FieldValue;

  // Get a reference to the database (prefer FirebaseWorker if we have it).
  /** @type {import('firebase/compat/app').default.database.Database} */
  const db = typeof globalThis.FirebaseWorker != 'undefined' ? globalThis.FirebaseWorker.database() : firebase.database();

  // Stores the last time the user updated a job
  let lastUpdatedLookup = {};

  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'LAST_UPDATED',
        pathMatch: /^photoheight\/jobs\/(?<jobId>[^\/\s]+)(?:\/.*)?$/,
        parse: async ({ jobId }) => ({ jobId }),
        onEventGroup: async (events) => {
          // Get a list of all the job IDs that were updated
          const jobIds = [...new Set(events.map((event) => event.jobId))];
          // Get the logged-in user's ID
          const userId = config.uid;
          // Get the current time in millis
          const currentMillis = DateTime.now().toMillis();

          // For each job ID, try to update the "last updated" time
          for (const jobId of jobIds) {
            // If we are calling this function more than once per minute, ignore the update
            if (currentMillis - (lastUpdatedLookup[jobId] || 0) < 60 * 1000) return;
            lastUpdatedLookup[jobId] = currentMillis;

            // Get the job owner for this job
            const jobOwner = await db
              .ref(`photoheight/jobs/${jobId}/job_owner`)
              .once('value')
              .then((s) => s.val());

            // Update the record in firestore
            const doc = firebase.firestore().doc(`companies/${jobOwner}/jobs_last_updated/${jobId}`);
            await doc.set(
              {
                last_updated: FieldValue.serverTimestamp(),
                last_updated_by: userId,
                ...(globalThis.isMobile && {
                  mobile_last_updated: FieldValue.serverTimestamp(),
                  mobile_last_updated_by: userId
                })
              },
              { merge: true }
            );
          }
        }
      }
    ]
  });
}
