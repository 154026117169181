import firebase from 'firebase/compat/app';
import { DateTime } from 'luxon';
import { Path } from './Path.js';

/**
 * @typedef {Object} EventType
 * @property {string} name - The name of the event.
 * @property {RegExp} pathMatch - A regular expression used to match the path of the update.
 * @property {(Object:{_update:Object,_timestamp:DateTime,[a:string]:any}) => Promise<Object|null>} parse
 * - A function that takes an update object and returns an event object (or a nullish value to ignore the update).
 *   The function's only argument is an object with the following properties:
 *   - _update: The update object.
 *   - _timestamp: The luxon timestamp of the update.
 *   - Any named capture groups from the path match.
 * @property {(event:Object) => Promise<void>} [onEvent] - A function that is called for each event.
 * @property {(events:Array<Object>) => Promise<void>} [onEventGroup] - A function that is called for each group of events.
 */

/** A class for parsing firebase updates. */
export class DatabaseUpdateParser {
  constructor(config) {
    /**
     * A regular expression used to filter out paths that should not be parsed.
     * @type {RegExp}
     */
    this.globalPathFilter = config.globalPathFilter;
    /**
     * An array of event types.
     * @type {Array<EventType>}
     */
    this.eventTypes = config.eventTypes;
  }

  /**
   * Returns a new DatabaseUpdateParser instance from a config object.
   * @param {Object} config - Configuration object.
   * @param {RegExp} config.globalPathFilter - A regular expression used to filter out paths that should not be parsed.
   * @param {Array<EventType>} config.eventTypes - An array of event types to configure.
   * @returns {DatabaseUpdateParser}
   */
  static fromConfig(config) {
    return new DatabaseUpdateParser(config);
  }

  /**
   * Parse a firebase update object and return a list of events.
   * @param {string} rootPath - firebase ref path that the update was applied to (defaults to database root)
   * @param {Object} update - update object containing all changes being written to firebase
   * @returns {Promise<[Array<Object>,Promise<void>]>} Response array containing:
   * - an array of the event objects
   * - a promise that resolves when all event handlers have completed
   */
  async parseUpdate(rootPath = '', update) {
    // Capture the timestamp for this update
    const updateTimestamp = DateTime.now();

    // If update is a primitive value, turn it into an update object.
    if (!Path.isObject(update)) update = { '/': update };
    // Flatten update and prepend with root path.
    const flattenedUpdate = Path.flattenObject(update, '/');
    const absolutePathUpdates = Object.entries(flattenedUpdate).map(([path, value]) => ({
      path: Path.clean(`${rootPath}/${path}`, '/'),
      value
    }));

    // Apply the global filter to the list of updates
    const filteredUpdates = absolutePathUpdates.filter((x) => this.globalPathFilter.test(x.path));
    // If no updates exist, end execution here
    if (filteredUpdates.length == 0) return [[], Promise.resolve()];

    // Process the updates and return a list of events
    const events = await this.#getEvents(filteredUpdates, updateTimestamp);

    // Call the event handlers
    const handlersRes = this.#callEventHandlers(events);

    return [events, handlersRes];
  }

  /**
   * Process a list of updates and return a list of update events.
   * @param {Object} updates - array of update objects
   * @param {DateTime} timestamp - timestamp for the update
   * @returns {Promise<Array<Object>>} - array of event objects
   */
  async #getEvents(updates, timestamp) {
    const events = [];

    // Loop through each update (in parallel)
    await Promise.all(
      updates.map(async (update) => {
        // Loop through each event type
        for (const eventType of this.eventTypes) {
          // Determine if the update matches the event
          const match = eventType.pathMatch.exec(update.path);
          // If the update does not match the event, skip this event
          if (!match) continue;
          // Parse the update into an event
          const event = await eventType.parse({ _update: update, _timestamp: timestamp, ...match.groups });
          // If the event is null, skip this update
          if (!event) continue;

          // Add the event to the list of events
          events.push({ _name: eventType.name, _timestamp: timestamp, ...event });
        }
      })
    );

    return events;
  }

  /**
   * Call the event handlers for each event.
   * @param {Array<Object>} events - array of event objects
   * @returns {Promise<void>}
   */
  async #callEventHandlers(events) {
    // Call individual event handlers
    const eventTypesWithHandlers = this.eventTypes.filter((event) => typeof event.onEvent == 'function');
    for (const eventType of eventTypesWithHandlers) {
      const eventsOfType = events.filter((event) => event._name == eventType.name);
      // Skip if no events of this type exist
      if (eventsOfType.length == 0) continue;
      await Promise.all(eventsOfType.map((event) => eventType.onEvent?.(event)));
    }

    // Call group event handlers
    const eventTypesWithGroupHandlers = this.eventTypes.filter((event) => typeof event.onEventGroup == 'function');
    for (const eventType of eventTypesWithGroupHandlers) {
      const eventsOfType = events.filter((event) => event._name == eventType.name);
      // Skip if no events of this type exist
      if (eventsOfType.length == 0) continue;
      await eventType.onEventGroup?.(eventsOfType);
    }
  }
}

/**
 * @typedef {object} FirebaseServiceOverrides
 * @property {import('firebase-admin')?} FIREBASE_ADMIN_SDK - The Firebase Admin SDK to use for the parser
 * @property {typeof import('firebase-admin').firestore.Timestamp?} FIREBASE_ADMIN_TIMESTAMP - The Timestamp class from Firebase Admin
 * @property {typeof import('firebase-admin').firestore.FieldValue?} FIREBASE_ADMIN_FIELD_VALUE - The FieldValue class from Firebase Admin
 */

/**
 * Get the Firebase SDK to use for the parser. Uses the Admin SDK override if available, then falls back to the globalThis.firebase, except
 * for the database, which uses the globalThis.FirebaseWorker if it can.
 * @param {FirebaseServiceOverrides?} OVERRIDES
 */
export function getFirebaseServices(OVERRIDES) {
  const firebaseSDK = OVERRIDES?.FIREBASE_ADMIN_SDK ?? /** @type {firebase} */ (/** @type {unknown} */ (globalThis.firebase));
  return {
    realtimeDB:
      typeof globalThis.FirebaseWorker != 'undefined'
        ? /** @type {firebase.database.Database} */ (globalThis.FirebaseWorker.database())
        : firebaseSDK.database(),
    firestoreDB: firebaseSDK.firestore(),
    auth: firebaseSDK.auth(),
    Timestamp: OVERRIDES?.FIREBASE_ADMIN_TIMESTAMP ?? firebaseSDK.firestore.Timestamp,
    FieldValue: OVERRIDES?.FIREBASE_ADMIN_FIELD_VALUE ?? firebaseSDK.firestore.FieldValue
  };
}

/**
 * @typedef {object} UserInfoOverrides
 * @property {string?} USER_UID - The user's uid to use
 * @property {string?} USER_ACTIVE_COMPANY - The user's active company to use
 * @property {string?} USER_ROOT_COMPANY - The user's root company to use
 */

/** @typedef {UserInfoOverrides & FirebaseServiceOverrides} UpdateParserOverrides */

/**
 * @typedef {object} AuthUserInfo
 * @property {string?} uid - The user's id
 * @property {string?} userGroup - The user's group
 * @property {string?} rootCompany - The user's root company
 */

/**
 * Get info about the authenticated user.
 * @param {UpdateParserOverrides?} OVERRIDES
 * @returns {Promise<AuthUserInfo>} The user's group, root company, and uid (null if not authenticated)
 */
export async function getAuthenticatedUserInfo(OVERRIDES) {
  // If any one of the user info overrides are set, use them
  if (OVERRIDES?.USER_UID || OVERRIDES?.USER_ACTIVE_COMPANY || OVERRIDES?.USER_ROOT_COMPANY) {
    return { uid: OVERRIDES.USER_UID, userGroup: OVERRIDES.USER_ACTIVE_COMPANY, rootCompany: OVERRIDES.USER_ROOT_COMPANY };
  }

  // Otherwise, get the info from the Firebase SDK
  const { auth } = getFirebaseServices(OVERRIDES);
  const currentUser = 'currentUser' in auth && auth.currentUser;
  if (!currentUser) return { userGroup: null, rootCompany: null, uid: null };

  const tokenResult = await currentUser.getIdTokenResult();
  const { userGroup = null, rootCompany = null } = tokenResult.claims;
  return { uid: currentUser.uid, userGroup, rootCompany };
}
