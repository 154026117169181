import { DateTime } from 'luxon';
import { DatabaseUpdateParser, getFirebaseServices, getAuthenticatedUserInfo } from '../DatabaseUpdateParser.js';

/** @import { UpdateParserOverrides } from '../DatabaseUpdateParser.js' */

/**
 * Get a parser that updates the last updated timestamp of a job
 * @param {UpdateParserOverrides?} [OVERRIDES] - Overrides for using this parser in different contexts (e.g. on the server with admin)
 * @returns {DatabaseUpdateParser} A job-last-updated parser
 */
export function getLastUpdatedParser(OVERRIDES = null) {
  // Stores the last time the user updated a job
  const lastUpdatedLookup = {};

  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'LAST_UPDATED',
        pathMatch: /^photoheight\/jobs\/(?<jobId>[^\/\s]+)(?:\/.*)?$/,
        parse: async ({ jobId }) => ({ jobId }),
        onEventGroup: async (events) => {
          const { firestoreDB, realtimeDB, FieldValue } = getFirebaseServices(OVERRIDES);
          const { uid: userUid } = await getAuthenticatedUserInfo(OVERRIDES);
          // Get a list of all the job IDs that were updated
          const jobIds = [...new Set(events.map((event) => event.jobId))];
          // Get the current time in millis
          const currentMillis = DateTime.now().toMillis();

          // For each job ID, try to update the "last updated" time
          for (const jobId of jobIds) {
            // If we are calling this function more than once per minute, ignore the update
            if (currentMillis - (lastUpdatedLookup[jobId] || 0) < 60 * 1000) return;
            lastUpdatedLookup[jobId] = currentMillis;

            // Get the job owner for this job
            const jobOwner = (await realtimeDB.ref(`photoheight/jobs/${jobId}/job_owner`).once('value')).val();

            // Update the record in firestore
            const doc = firestoreDB.doc(`companies/${jobOwner}/jobs_last_updated/${jobId}`);
            await doc.set(
              {
                last_updated: FieldValue.serverTimestamp(),
                last_updated_by: userUid,
                ...(globalThis.isMobile && {
                  mobile_last_updated: FieldValue.serverTimestamp(),
                  mobile_last_updated_by: userUid
                })
              },
              { merge: true }
            );
          }
        }
      }
    ]
  });
}
