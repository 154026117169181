import { DatabaseUpdateParser, getFirebaseServices, getAuthenticatedUserInfo } from '../DatabaseUpdateParser.js';
import { DateTime } from 'luxon';

/** @import { UpdateParserOverrides } from '../DatabaseUpdateParser.js' */

/**
 * Get a parser for geo-location events on jobs
 * @param {UpdateParserOverrides?} [OVERRIDES] - Overrides for using this parser in different contexts (e.g. on the server with admin)
 */
export function getGeoEventsParser(OVERRIDES = null) {
  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'NODE_LOCATION_SET',
        pathMatch: /^photoheight\/jobs\/(?<jobId>[^\/\s]+)\/nodes\/(?<nodeId>[^\/\s]+)\/(?<latOrLng>latitude|longitude)$/,
        parse: async ({ _update, _timestamp, jobId, nodeId, latOrLng }) => ({
          jobId,
          nodeId,
          type: latOrLng,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/nodes/${nodeId}`
        }),
        onEventGroup: async (events) => await writeGeoEventsToFirestore(events, 'NODE', OVERRIDES)
      },
      {
        name: 'SECTION_LOCATION_SET',
        pathMatch:
          /^photoheight\/jobs\/(?<jobId>[^\/\s]+)\/connections\/(?<connectionId>[^\/\s]+)\/sections\/(?<sectionId>[^\/\s]+)\/(?<latOrLng>latitude|longitude)$/,
        parse: async ({ _update, _timestamp, jobId, connectionId, sectionId, latOrLng }) => ({
          jobId,
          connectionId,
          sectionId,
          type: latOrLng,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/connections/${connectionId}/sections/${sectionId}`
        }),
        onEventGroup: async (events) => await writeGeoEventsToFirestore(events, 'SECTION', OVERRIDES)
      }
    ]
  });
}

/**
 * Write geo-location events to Firestore
 * @param {Array<Object>} events - array of event objects
 * @param {string} entityType - the type of entity that the events are for
 * @param {UpdateParserOverrides?} OVERRIDES - Overrides for using this parser in different contexts (e.g. on the server with admin)
 */
async function writeGeoEventsToFirestore(events, entityType, OVERRIDES) {
  const { realtimeDB, Timestamp, firestoreDB } = getFirebaseServices(OVERRIDES);
  // Get a list of all the unique job-nodes that were updated
  const jobSubPaths = [...new Set(events.map((event) => event.jobSubPath))];
  // Get the logged-in user's id and active company
  const { uid: userId, userGroup } = await getAuthenticatedUserInfo(OVERRIDES);
  // Get the local timestamp as a firestore timestamp
  const timestamp = Timestamp.fromMillis(DateTime.now().toMillis());

  // Get a ref to the jobs list
  const jobsRef = realtimeDB.ref(`photoheight/jobs`);
  const jobOwnersByJobId = {};

  // For each job node, create geo-location event records for each node
  await Promise.all(
    jobSubPaths.map(async (jobSubPath) => {
      // Filter to the events for this job-node or job-section
      const latUpdate = events.find((event) => event.jobSubPath == jobSubPath && event.type === 'latitude');
      const lngUpdate = events.find((event) => event.jobSubPath == jobSubPath && event.type === 'longitude');

      // Get the event data (or fetch it from the database if necessary)
      const combinedLatLngEvent = { ...latUpdate, ...lngUpdate };
      const latitude = latUpdate?.value ?? (await jobsRef.child(`${jobSubPath}/latitude`).once('value')).val();
      const longitude = lngUpdate?.value ?? (await jobsRef.child(`${jobSubPath}/longitude`).once('value')).val();
      const jobId = combinedLatLngEvent?.jobId;
      const entityId = combinedLatLngEvent?.nodeId ?? combinedLatLngEvent?.sectionId;
      // If this is a section, include the connection as the parent
      const parent = entityType == 'SECTION' ? { entity_id: combinedLatLngEvent?.connectionId, entity_type: 'CONNECTION' } : null;

      // Get the job owner from the cache (or fetch it and cache it)
      const jobOwner = (jobOwnersByJobId[jobId] ??= (await jobsRef.child(`${jobId}/job_owner`).once('value')).val());

      // Create the geo-event record
      const geoEvent = {
        value: { latitude, longitude },
        entity_id: entityId,
        entity_type: entityType,
        parent: parent,
        entity_was_deleted: false,
        entity_path: `photoheight/jobs/${jobSubPath}`,
        job_id: jobId,
        job_owner: jobOwner,
        set_at: timestamp,
        set_by: { uid: userId, user_group: userGroup }
      };

      // Add the geo-event to the firestore collection
      await firestoreDB.collection(`jobs/${jobId}/geo_events`).add(geoEvent);
    })
  );
}
